import styled from "styled-components"
import { pageMargins, remCalc } from "./utils"
import { typography } from "../styles/variables"
import { respondTo } from "../styles/respondTo"

const StyledStats = styled.div`
  width: 100%;
  ${pageMargins};
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 5%;
  //justify-content: space-between;
  align-items: ${({ align }) => (align ? align : "center")};
  margin-bottom: 0rem;
  padding-bottom: 2rem;
  padding-top: 2rem;
  &.overview-stats {
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
  }
  &.headline-stats {
    @media only screen and (min-width: 1024px) {
      & .stat_item {
        /* margin: 3rem 0; */
      }
    }
  }
  &.social-value {
    @media only screen and (max-width: 640px) {
      padding-bottom: 2rem;
    }
  }
  &.no-padding-b-d {
    @media only screen and (min-width: 1024px) {
      padding-bottom: 0.5rem;
    }
    @media only screen and (max-width: 640px) {
      padding-top: 0;
      margin-top: 0;
    }
  }
  &.single {

    @media only screen and (max-width: 1024px) {
      display: flex;

      & .stat_item,
      & .stat_item.item-circle_image {
        margin-bottom: 2.25rem;
      }
    }
    @media only screen and (max-width: 640px) {

      //& .stat_item {
      //  marign-left: auto;
      //  marign-right: auto;
      //}
    }
  }

  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    margin-top: 2rem;
    align-items: flex-start;
    flex-direction: column;
    display: grid;
    grid-row-gap: 2.25rem;

    grid-template-rows: auto;

    &.covid-stats {
      grid-gap: unset;
      grid-row-gap: 2rem;
    }
    &.overview-stats {
      display: flex;
    }
  }
  @media only screen and (max-width: 640px) {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 0;
  }

  &.covid-stats {
    @media only screen and (max-width: 640px) {
      margin-top: 0;
      padding-top: 0;
    }
  }
  & .stat_title {
    & h3 {
      font-size: 56px;
      font-family: ${typography.type.primary};
      ${respondTo.l`
    font-size: 72px;
    `};
    }
    margin-bottom: 1rem;
    display: flex;
    @media only screen and (max-width: 1024px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  & .large-para {
    & p,
    & h3 {
      font-size: 1.5rem !important;
      line-height: 33px !important;
      @media only screen and (max-width: 1024px) {
        font-size: 20px;
        line-height: 27px;
      }
    }
    & h3 {
      font-family: ${typography.type.primary};
      margin-bottom: 1em;
    }

    & a {
      color: black !important;
    }
  }

  & .stat_item {
    //width: 30%;
    position: relative;
    /* padding-right: 5%; */
    margin: 1rem 0;

    & .svg-title_container {
      display: flex;
      align-items: center;
      height: ${remCalc(37)};
      @media only screen and (max-width: 640px) {
        & > svg {
          width: 80px;
          height: 80px;
        }
      }
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      & .stat_para {
        width: 100%;
        p {
          text-align: left;
        }
      }
    }
    @media only screen and (max-width: 640px) {
      /* padding-bottom: 2rem; */
    }
    &.item-circle_image {
      @media only screen and (max-width: 1024px) {
        width: 242px;
        height: 242px;
        margin: 0 auto;
        & .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
    & svg {
      height: 4.5vw;
      margin-bottom: 0.4rem;
      margin-right: 0.7rem;
      @media only screen and (max-width: 1024px) {
        height: 60px;
      }
    }
    p {
      font-size: 1.25rem;
      line-height: ${remCalc(30)};
      @media only screen and (max-width: 640px) {
        font-size: 18px;
        line-height: 24px;
      }
    }
    @media only screen and (max-width: 1024px) {
      width: 100%;

      padding-right: 0;
      & p {
        text-align: center;
      }
      &.m-w-100 {
        & p {
          text-align: left;
        }
      }
      & svg {
        float: left;
      }
    }
    @media only screen and (max-width: 640px) {
      max-width: 285px;
    }
  }
  & .stats_image {
    transform: translate(0, -10%);
    @media only screen and (max-width: 1024px) {
      height: 295px;
      width: 295px !important;
      grid-column: 1/2;
      grid-row: 1/2;
      & .gatsby-image-wrapper {
        width: 100%;
      }
    }
    @media only screen and (max-width: 640px) {
      margin: 3.5rem 0 0 0;
    }
    &.no-transform {
      transform: translate(0, 0);
    }
  }

  &.sustainability {
    width: 100%;
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    display: grid;
    grid-column-gap: 2rem;
    padding-bottom: 2rem;
    padding-top: 2rem;

    & .row-second,
    .row-third {
      & p {
        font-family: ${typography.type.secondary};
      }
    }
    @media only screen and (max-width: 1024px) {
      grid-row-gap: 1rem;

      padding-bottom: 1.2rem;
      padding-top: 0rem;
    }
    @media only screen and (max-width: 640px) {
      margin-top: 0rem;
      padding-bottom: 3rem;
      & .title_container {
        margin-top: 2rem;

        & > svg {
          width: 80px;
          height: 80px;
        }

        &.first {
          width: 100%;
          grid-row: 1/2;
        }
        &.second {
          grid-row: 2/3;
        }
        &.third {
          grid-row: 5/6;
        }
      }
      & .col-second {
        &.row-first {
          grid-row: 3/4;
        }
        &.row-second {
          grid-row: 3/4;
        }
        &.row-third {
          grid-row: 4/5;
        }
      }
      & .col-third {
        &.row-first {
          grid-row: 6/7;
        }
        &.row-second {
          grid-row: 7/8;
        }
        &.row-third {
          grid-row: 8/9;
        }
      }
    }

    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(2, minmax(200px, 1fr));
    }
    @media only screen and (max-width: 640px) {
      grid-template-columns: 1fr;
    }
    & p {
      font-size: ${remCalc(18)};
      line-height: ${remCalc(28)};
    }
    & .row-second,
    .row-third {
      & p {
        font-size: ${remCalc(18)};
        line-height: ${remCalc(28)};
      }
    }

    & .row-second {
      grid-row: 2/3;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        grid-row: unset;
      }
    }
    & .row-third {
      grid-row: 3/4;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        grid-row: unset;
        &.col-second {
          grid-row: 3/4;
          margin-bottom: 3rem;
        }
      }
    }
    & .col-first {
      grid-column: 1/2;
    }
    & .col-second {
      grid-column: 2/3;
      @media only screen and (max-width: 640px) {
        grid-column: 1/2;
      }
    }
    & .col-third {
      grid-column: 3/4;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        grid-column: 2/3;
      }
      @media only screen and (max-width: 640px) {
        grid-column: 1/2;
      }
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      & .row_second.col_third {
        grid-row: 4/5;
      }
    }

    & .title_container {
      display: flex;
      align-items: center;
      grid-row: 1/2;
      height: 100%;
      @media only screen and (max-width: 1024px) and (min-width: 640px) {
        &.third {
          grid-row: 3/4;
          grid-column: 2/3;
          margin-top: 0rem;
          margin-bottom: 1.5rem;
        }
        &.m-t-2 {
          margin-top: 2rem;
        }
      }
      @media only screen and (max-width: 640px) {
        &.third {
          grid-column: 1/2;
        }
      }
    }
    & .first {
      & svg {
        margin-right: 1rem;
      }
    }
  }
  & .stat_item {
    grid-column: span 1;

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;

      &:not(.stats_image) {
        grid-column: 2/3;
      }
      & .stat_title {
        justify-content: flex-start;
      }

      &.flex-tablet {
        display: flex;
        flex-direction: row;
        & .stat_title,
        .stat_para {
          width: 50%;
        }
      }
    }

    &--aligned {
      grid-column: span 2;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 5%;

      .stat_item__inner {}

      @media only screen and (max-width: 1024px) {
        display: block;

        .stat_item__inner {
          margin-bottom: 2rem;
        }
      }
    }
  }
  &.jobs_stats {
    align-items: center;
    &.community-employment,
    &.job-creation {
      & .stats_image {
        transform: translate(0, 0);
      }
    }
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      &.community-employment,
      &.job-creation {
        align-items: flex-start;
      }
    }
    @media only screen and (max-width: 640px) {
      &.community-employment {
        flex-direction: column-reverse;
        padding-bottom: 2rem;
      }
    }
  }
  &.stats_two-part {
    justify-content: space-around;

    & .stats_image {
      transform: translate(0, 0);
    }
    & .stat_item:nth-child(1) {
      @media only screen and (min-width: 1024px) {
        width: 40%;
      }
    }
    @media only screen and (max-width: 640px) {
      margin-top: 0;
      padding-top: 0;
      padding-bottom: 1rem;
    }
  }
`

export default StyledStats

import React from "react"
import Layout from "../layouts"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import Hero from "../components/Hero"
import LetterAnimation from "../animations/LetterAnimation"
import List from "../components/List"
import Item from "../components/List/Item"
import Arrow from "../images/svgs/Explore"
import CircleAndText from "../components/CircleAndText"
import StyledStats from "../styles/StyledStats"
import { PageContainer } from "../styles/global"
import { pageMargins } from "../styles/utils"
import { typography, color } from "../styles/variables"
import SusIcon from "../images/svgs/SustainabilityIcons"
import NextPage from "../components/NextPage"
import Dots from "../components/Dots"
import Line from "../components/Line"
import animationData from "../animations/DESKTOP_MOBILE/07-Environment.json"
import mobileAnimationData from "../animations/DESKTOP_MOBILE/07-Environment_MOBILE.json"
import useIsTablet from "../hooks/useIsTablet"

const StyledSustainability = styled.div`
  width: 100%;
  & .dots_title {
    font-family: ${typography.type.primary} !important;
  }
  & .sustainability_section-title {
    font-size: 2rem;
    letter-spacing: 0.2px;
    padding-bottom: 2rem;
    font-family: ${typography.type.primary};
    @media only screen and (max-width: 640px) {
      font-size: 20px;
    }
  }
  & .title-padding {
    ${pageMargins};
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  & .table_title {
    font-family: ${typography.type.primary};
    font-size: 1.5rem !important;
    font-weight: bold;
    margin-bottom: 0.5rem;
    @media only screen and (max-width: 640px) {
      font-size: 1.25rem !important;
      margin-bottom: 0rem;
    }
  }
  & .dot-blue,
  .dot-green,
  .dot-grey {
    display: flex;
    align-items: center;
    margin: 0.7rem 0 1.8rem;
    @media only screen and (max-width: 640px) {
      margin: 0.7rem 0 0 0;
    }
  }

  & .dot-blue {
    & .dot_single {
      border: 1px solid #0022f5;
    }

    & p {
      color: #0022f5;
    }
    & .active {
      background: #0022f5;
    }
  }
  & .dot-green {
    & .dot_single {
      border: 1px solid #61d14f;
    }

    & p {
      color: #61d14f;
    }
    & .active {
      background: #61d14f;
    }
  }
  & .dot-grey {
    & .dot_single {
      border: 1px solid hsla(0, 0%, 0%, 0.8);
    }

    & p {
      color: hsla(0, 0%, 0%, 0.8);
    }
    & .active {
      background:hsla(0, 0%, 0%, 0.8);
    }
  }
  & .dots {
    display: flex;
    align-items: center;
    & .dot_single {
      width: 1.5rem;
      height: 1.5rem;
      border-radius: 50%;
      margin-right: 0.4rem;
    }
  }
  & .grey_padding-element.environmental {
    grid-column: 1/-1;
  }
`

const sustainability = ({ location }) => {
  const isTablet = useIsTablet()
  const data = useStaticQuery(graphql`
    query {
      bgImage: file(relativePath: { eq: "sustainability-header-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      sustainability: file(relativePath: { eq: "sustainability-1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout location={location}>
      <Hero
        bg="black"
        animationData={animationData}
        mobileAnimationData={mobileAnimationData}
        bgImage={data.bgImage}
        mobileColors={color.header.sustain}
        location={location.pathname}
        headerColor="#0022F5"
      >
        {isTablet ? (
          <h1 className="hero_title">Environmental sustainability</h1>
        ) : (
          <LetterAnimation duration={0.6} delay={0.015} initDelay={0.1}>
            <h1 className="hero_title text-animated">
              <span className="line-inner">Environmental</span>
            </h1>
            <h1 className="hero_title text-animated">
              <span className="line-inner">sustainability</span>
            </h1>
          </LetterAnimation>
        )}
      </Hero>
      <StyledSustainability className="white-bg">
        <PageContainer>
          <CircleAndText image={data.sustainability}>
            <div
              data-scroll
              data-scroll-speed="1"
              className="circle-text_container first-section"
            >
              <p>Our impact on the environment matters to us. It’s why we place environmental sustainability at the heart of everything we do. Landsec was the first commercial property company in the world to commit to science based targets in 2016, and our ambition hasn’t lessened since then.
                <br />
                <br />
                We have committed to becoming a net zero carbon business by 2030 and are aiming to deliver the UK’s first commercial net zero carbon building – The Forge SE1 – through innovative design and a world-first in off-site construction methods.
              </p>
              <List device="desktop">
                <Item scrollElementClass="natural-resources">
                  <p>Efficient use of natural resources</p>
                  <Arrow />
                </Item>
                <Item scrollElementClass="design-innovation">
                  <p>Sustainable design and innovation</p>
                  <Arrow />
                </Item>
              </List>
            </div>
            <List device="tablet">
              <Item scrollElementClass="natural-resources">
                <p>Efficient use of natural resources</p>
                <Arrow />
              </Item>
              <Item scrollElementClass="design-innovation">
                <p>Sustainable design and innovation</p>
                <Arrow />
              </Item>
            </List>
          </CircleAndText>
          <div className="title-padding">
            <h3 className="sustainability_section-title natural-resources  default-border">
              Efficient use of natural resources
            </h3>
          </div>

          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="carbon" />
              <p className="table_title">Carbon</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>
            <div className="row-second col-second">
              <p>Reduce carbon emissions (tCO2e) by 70% by 2030 compared with a 2013/14 baseline, for property under our management for at least two years.</p>
            </div>
            <div className="row-second col-third">
              <p>We have reduced carbon emissions by 55% since 2013/14 against our science-based carbon reduction target. Significant reduction in carbon emissions as a result of lower occupancy and operational hours due to Covid-19 restrictions.
              </p>
              <Dots amount={2} />
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="renewables" />
              <p className="table_title">Renewables</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title m-t-2">Progress</p>

            <div className="row-second col-second">
              <p>Ensure 100% of our electricity supplies through our corporate contract are from REGO backed renewable sources.</p>
            </div>
            <div className="row-third col-second">
              <p>Achieve 3MW of renewable electricity capacity by 2030.</p>
            </div>
            <div className="row-second col-third">
              <p>We continue to procure 100% renewable electricity across our portfolio. We are currently exploring opportunities to move our procurement towards direct purchasing from renewable projects through Power Purchase Agreements (PPA).</p>
              <Dots amount={3} />
            </div>
            <div className="row-third col-third">
              <div className="third">
                <p>Our current on-site renewable electricity capacity is 1.4 MW. We have continued to progress our feasibility studies for on-site renewable technologies, assessing the value this would deliver to Landsec and our customers and how these could be incorporated as part of future redevelopment works.</p>
                <Dots amount={2} />
              </div>
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="energy" />
              <p className="table_title">Energy</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>
            <div className="row-second col-second">
              <p>Reduce energy intensity (kWh/m2) by 40% by 2030 compared with a 2013/14 baseline, for property under our management for at least two years.</p>
            </div>
            <div className="row-second col-third">
              <p>We have reduced energy intensity by 43% compared to 2013/14. Although this figure suggests that we’ve already achieved our target to reduce energy intensity by 40% by 2030, we recognise that energy consumption has been significantly impacted by lower occupancy and operational hours due to Covid-19 restrictions and doesn’t reflect portfolio energy performance in normal conditions. For that reason, we’ll continue tracking our performance against this 2030 target.</p>
              <Dots amount={2} />
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="waste" />
              <p className="table_title">Waste</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title m-t-2">Progress</p>

            <div className="row-second col-second">
              <p>Send zero waste to landfill.</p>
            </div>
            <div className="row-second col-third">
              <p>We continue to divert 100% of waste from landfill across our operational activities.</p>
              <Dots amount={3} />
            </div>

            <div className="row-third col-second">
              <p>At least 75% waste recycled across all our operational activities by 2020.</p>
            </div>
            <div className="row-third col-third">
              <p>We recycled 65% of operational waste. The decrease in recycling rate from last year reflects the reduction of recyclable materials such as packaging in retail as a result of Covid-19. Landsec has seen a 63% reduction in total waste produced due to Covid-19.</p>
              <Dots amount={0} />
            </div>
          </StyledStats>
          <div className="title-padding">
            <h3 className="sustainability_section-title design-innovation  default-border">
              Sustainable design and innovation
            </h3>
          </div>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="resilience" />
              <p className="table_title">Resilience</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>

            <div className="row-second col-second">
              <p>Assess and mitigate physical and financial climate change adaptation risks that are material across our portfolio.</p>
            </div>
            <div className="row-second col-third">
              <p>To continue aligning our disclosures with the TCFD recommendations, this year we’ve again worked with Willis Towers Watson in assessing and quantifying climate-related risks to inform our approach to managing climate risks across our portfolio, including new developments.</p>
              <Dots amount={2} />
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="materials" />
              <p className="table_title">Materials</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>

            <div className="row-second col-second">
              <p>Source core construction products and materials from ethical and sustainable sources.</p>
            </div>
            <div className="row-second col-third">
              <p>Our developments continue to make good progress against this target. All our live developments are targeting 100% of core construction materials with a responsible sourcing certification and from the UK and Europe to reduce emissions from transportation and reduce risk of ethical issues in manufacture and extraction.</p>
              <Dots amount={2} />
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="biodiversity" />
              <p className="table_title">Biodiversity</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>

            <div className="row-second col-second">
              <p>Maximise the biodiversity potential of all our development and operational sites and achieve a 25% biodiversity net gain across our five operational sites currently offering the greatest potential, by 2030.</p>
            </div>
            <div className="row-second col-third">
              <p>We continued our partnership with The Wildlife Trusts to enhance biodiversity net gain at our five operational sites and we’re on track to deliver significant net gain on our developments and created a new biodiversity brief for developments.</p>
              <Dots amount={2} />
            </div>
            <Line />
          </StyledStats>
          <StyledStats className="sustainability" align="flex-start">
            <div className="title_container first svg-title_container">
              <SusIcon icon="wellbeing" />
              <p className="table_title">Wellbeing</p>
            </div>
            <p className="title_container second table_title">Commitment</p>
            <p className="title_container third table_title">Progress</p>

            <div className="row-second col-second">
              <p>Ensure our buildings are designed and managed to maximise wellbeing and productivity.</p>
            </div>
            <div className="row-second col-third">
              <p>This year we committed to pursuing the WELL Portfolio Programme across our existing managed office portfolio, in addition to our new schemes. All of our live developments are successfully registered and pre-assessed against WELL Core rating.</p>
              <Dots amount={2} />
            </div>
          </StyledStats>
          <StyledStats style={{ marginTop: 0, paddingTop: 0 }}>
            <div className="grey_padding-element environmental">
              <p>All data correct as of 31 March 2021.</p>
            </div>
          </StyledStats>
          <NextPage
            page={{
              title: "Social sustainability",
              path: "social-sustainability",
            }}
            colorKey="social_value"
          />
        </PageContainer>
      </StyledSustainability>
    </Layout>
  )
}

export default sustainability

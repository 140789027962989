import * as React from "react"
import { v4 as uuidv4 } from "uuid"
const paths = {
  carbon: [
    "M26.7981 24.5L60.2505 57.9525L60.2503 39.1252L63.5002 35.9231L63.5003 63.5003H35.9231L39.1491 60.2743L57.9521 60.2502L44.0001 46.2983L24.5 26.7982L26.7981 24.5Z",
  ],
  renewables: [
    "M55.375 33.7507C55.3801 32.27 55.0929 30.803 54.5298 29.4336C53.9667 28.0642 53.1388 26.8194 52.0936 25.7707C51.0485 24.7219 49.8065 23.8898 48.4391 23.322C47.0716 22.7542 45.6056 22.4619 44.1249 22.4619C42.6443 22.4619 41.1782 22.7542 39.8108 23.322C38.4433 23.8898 37.2014 24.7219 36.1562 25.7707C35.1111 26.8194 34.2832 28.0642 33.7201 29.4336C33.157 30.803 32.8698 32.27 32.8748 33.7507C30.3883 33.7507 28.0037 34.7384 26.2455 36.4966C24.4873 38.2548 23.4995 40.6395 23.4995 43.126C23.4995 45.6125 24.4873 47.9971 26.2455 49.7553C28.0037 51.5135 30.3883 52.5013 32.8748 52.5013H55.375C57.8615 52.5013 60.2462 51.5135 62.0044 49.7553C63.7626 47.9971 64.7504 45.6125 64.7504 43.126C64.7504 40.6395 63.7626 38.2548 62.0044 36.4966C60.2462 34.7384 57.8615 33.7507 55.375 33.7507Z",
    "M46.0001 56.252L38.5001 63.7522L31 56.252H46.0001Z",
    "M42.25 63.7559L49.7476 56.252L57.2503 63.7559H42.25Z",
  ],
  energy: [
    "M44.0002 26.0244C40.9696 26.0244 38.0187 26.9958 35.5808 28.7961C33.1428 30.5963 31.346 33.1306 30.4542 36.0271C29.5624 38.9235 29.6225 42.0295 30.6256 44.8893C31.6288 47.7491 33.5221 50.2121 36.0279 51.9167V59.6857L41.3428 65.0004H46.6576L51.9725 59.6857V51.9156C51.996 51.8997 52.0201 51.8846 52.0436 51.8684C54.5328 50.1527 56.4081 47.6859 57.3957 44.8286C58.3832 41.9712 58.4314 38.873 57.5329 35.9863C56.6345 33.0997 54.8367 30.5759 52.402 28.7837C49.9674 26.9914 47.0234 26.0246 44.0002 26.0244ZM50.0304 48.9534C49.5241 49.3025 48.9884 49.6069 48.4293 49.8631V54.371H39.5712V49.8623C37.4381 48.8846 35.6883 47.2286 34.5948 45.1525C33.5014 43.0763 33.1255 40.6967 33.526 38.3846C33.9265 36.0725 35.0807 33.9579 36.8087 32.3705C38.5368 30.783 40.7415 29.812 43.0792 29.6087C45.4169 29.4054 47.7562 29.9813 49.7323 31.2467C51.7084 32.512 53.2103 34.3957 54.0039 36.6039C54.7974 38.8122 54.838 41.221 54.1192 43.4547C53.4005 45.6884 51.9629 47.6216 50.0305 48.9528L50.0304 48.9534Z",
    "M27.9034 23.0004L25.3979 25.5059L28.9412 29.0491L31.4467 26.5436L27.9034 23.0004Z",
    "M56.5536 26.5433L59.0591 29.0488L62.6023 25.5056L60.0968 23.0001L56.5536 26.5433Z",
    "M31.4469 53.3326L28.9414 50.8271L25.3982 54.3704L27.9037 56.8759L31.4469 53.3326Z",
    "M60.0966 56.8766L62.6021 54.3711L59.0588 50.8278L56.5533 53.3333L60.0966 56.8766Z",
  ],
  waste: [
    "M28.8125 44.0001C28.8125 40.7418 29.8604 37.5698 31.8014 34.9528C33.7424 32.3357 36.4736 30.4122 39.5916 29.4664C42.7096 28.5206 46.0491 28.6027 49.1169 29.7004C52.1847 30.7981 54.8182 32.8533 56.6284 35.5625H52.4374L48.2185 39.7814H64.2502V23.75L60.0316 27.9687V31.6306C58.1389 29.1797 55.7098 27.195 52.9308 25.8289C50.1518 24.4629 47.0967 23.7517 44.0001 23.75C32.8342 23.75 23.75 32.8345 23.75 44.0001H28.8125Z",
    "M59.1878 44C59.1877 47.2583 58.1399 50.4302 56.1989 53.0473C54.2579 55.6644 51.5267 57.5878 48.4087 58.5336C45.2907 59.4794 41.9511 59.3974 38.8833 58.2997C35.8155 57.202 33.182 55.1467 31.3718 52.4375H35.5629L39.7816 48.2188H23.75V64.2502L27.9687 60.0314V56.3695C29.8613 58.8204 32.2904 60.8051 35.0694 62.1711C37.8485 63.5372 40.9035 64.2484 44.0002 64.2501C55.1661 64.2501 64.2502 55.1655 64.2502 44H59.1878Z",
  ],
  resilience: [
    "M69.4998 37.6251V33.375H65.0695C64.751 31.4791 64.0055 29.6804 62.8893 28.1151L66.0229 24.9815L63.018 21.9766L59.8844 25.1102C58.3191 23.994 56.5203 23.2484 54.6243 22.9299V18.5H50.3747V22.9296C48.4787 23.2481 46.68 23.9936 45.1146 25.1099L41.9811 21.9763L38.9761 24.9818L42.1097 28.1154C40.9935 29.6808 40.248 31.4795 39.9294 33.3755H35.4995V37.6256H39.9291C40.2477 39.5216 40.9932 41.3203 42.1094 42.8856L38.9758 46.0192L41.9811 49.0244L45.1146 45.8909C46.68 47.0069 48.4787 47.7523 50.3747 48.0706V52.5003H54.6246V48.0706C56.5206 47.7521 58.3193 47.0066 59.8847 45.8904L63.0183 49.024L66.0235 46.0188L62.8899 42.8852C64.0059 41.3198 64.7512 39.5211 65.0695 37.6251H69.4998ZM52.4997 37.6251C52.0793 37.6251 51.6685 37.5005 51.319 37.267C50.9695 37.0335 50.6971 36.7016 50.5363 36.3133C50.3754 35.9249 50.3334 35.4976 50.4154 35.0854C50.4974 34.6732 50.6998 34.2945 50.997 33.9973C51.2942 33.7001 51.6728 33.4977 52.0851 33.4157C52.4973 33.3337 52.9246 33.3758 53.3129 33.5366C53.7012 33.6975 54.0331 33.9699 54.2666 34.3193C54.5002 34.6688 54.6248 35.0797 54.6248 35.5C54.6242 36.0635 54.4001 36.6037 54.0017 37.0021C53.6033 37.4005 53.0631 37.6246 52.4997 37.6253V37.6251Z",
    "M32.3121 46.125H28.0622V48.49C26.3069 48.8913 24.7005 49.7795 23.4273 51.0527C22.1541 52.3258 21.2659 53.9323 20.8645 55.6875H18.4995V59.9313H20.863C21.2604 61.6744 22.138 63.2715 23.3964 64.5415C24.6548 65.8115 26.2437 66.7037 27.9831 67.1171V69.5001H32.3121V67.1352C34.0674 66.7339 35.6738 65.8456 36.947 64.5725C38.2201 63.2993 39.1084 61.6928 39.5097 59.9376H41.8752V55.6875H39.5097C39.1083 53.9324 38.22 52.3261 36.9468 51.0531C35.6736 49.7801 34.0672 48.892 32.3121 48.4908V46.125ZM30.1871 63.1251C29.1364 63.1251 28.1093 62.8136 27.2356 62.2298C26.362 61.646 25.6811 60.8163 25.279 59.8456C24.8769 58.8748 24.7717 57.8066 24.9767 56.7761C25.1817 55.7455 25.6877 54.7989 26.4307 54.056C27.1737 53.313 28.1203 52.8071 29.1509 52.6021C30.1814 52.3972 31.2496 52.5024 32.2204 52.9046C33.1911 53.3067 34.0208 53.9877 34.6045 54.8614C35.1882 55.735 35.4997 56.7622 35.4997 57.8129C35.4981 59.2214 34.9379 60.5717 33.9419 61.5677C32.946 62.5636 31.5956 63.1239 30.1871 63.1254V63.1251Z",
  ],
  materials: [
    "M42 20c-12.318 1.017-22 11.419-22 24a23.999 23.999 0 0039.946 17.936L42.001 44V20z",
    "M62.556 59.222a23.998 23.998 0 00-.272-30.767L47.037 43.703l15.519 15.519zM59.449 25.634C55.75 22.521 51.103 20.421 46 20v19.082l13.448-13.448z",
  ],
  biodiversity: [
    "M59.126 58.7497V51.0132C60.7357 50.5968 62.1615 49.6576 63.1795 48.3431C64.1975 47.0285 64.75 45.413 64.7503 43.7503V40.0001L61.0003 43.7502L57.2502 40.0001L53.5003 43.7502L49.7502 40.0001V43.7502C49.7503 45.4132 50.3029 47.0291 51.3212 48.3439C52.3395 49.6587 53.7658 50.5979 55.3759 51.014V58.7497H40.3764V39.8054C42.9973 39.3619 45.3765 38.0047 47.0923 35.9745C48.8081 33.9444 49.7497 31.3722 49.7502 28.7141V23.125L44.1256 28.7499L38.5005 23.125L32.8754 28.7499L27.2501 23.125V28.7148C27.2506 31.3734 28.1925 33.9459 29.9089 35.9762C31.6252 38.0066 34.005 39.3636 36.6265 39.8066V46.1317C36.0568 45.8 35.4094 45.6252 34.7502 45.6252H29.1251L25.3751 49.3752L29.1251 53.1251H34.7502C35.4094 53.1252 36.0568 52.9504 36.6265 52.6186V58.7497H23.5V62.5002H68.5004V58.7497H59.126Z",
  ],
  wellbeing: [
    "M53.063 26.088A12.646 12.646 0 0044 29.899a12.687 12.687 0 10-17.515 18.336L44 65.75l17.515-17.515a12.687 12.687 0 00-8.453-22.147zm0 19.724h-7.264v7.25h-3.611v-7.25h-7.272v-3.631h7.272v-7.244H45.8v7.244h7.264l-.002 3.631z",
  ],
}

function SvgComponent(props) {
  return (
    <svg
      width={88}
      height={88}
      viewBox="0 0 88 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={44} cy={44} r={44} fill="#0022F5" />
      {paths[props.icon].map(item => (
        <path key={uuidv4()} d={item} fill="#fff" />
      ))}
    </svg>
  )
}

export default SvgComponent

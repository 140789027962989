import React from "react"
import styled from "styled-components"
import { pageMargins, remCalc } from "../styles/utils"
import { typography } from "../styles/variables"
import Img from "gatsby-image"

const StyledCircleAndText = styled.div`
  width: 100%;
  ${pageMargins};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 5rem;
  min-height: 25rem;
  position: relative;
  @media only screen and (max-width: 1024px) and (min-width: 640px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 640px) {
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
    & > * {
      padding-bottom: 1.5rem;
    }
  }
  & .candt_circle {
    width: 30vw;
    height: 30vw;
    max-width: 30rem;
    max-height: 30rem;
    border-radius: 50%;
    position: relative;

    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      width: 296px;
      height: 296px;
      max-width: 296px;
      max-height: 296px;
    }
    @media only screen and (max-width: 640px) {
      width: 242px;
      height: 242px;
      max-width: 242px;
      max-height: 242px;
      margin-bottom: 2rem;
    }
  }
  & p {
    font-size: 1.25rem;
    line-height: 2.1rem;
    width: 50%;
    font-family: ${typography.type.primary};
    @media only screen and (max-width: 1024px) and (min-width: 640px) {
      width: 50%;
    }

    @media only screen and (max-width: 640px) {
      font-size: 18px;
      line-height: 28px;
      width: 100%;
    }
  }
  .circle-text_container {
    width: 50%;
    display: flex;
    flex-direction: column;
    position: relative;
    & p,
    .list {
      width: 100%;
      font-size: 1.25rem;
    }
    p {
      margin-bottom: 1rem;

      &:last-child,
      &:only-child,
      &:only-of-type {
        margin-bottom: 0;
      }
    }
    @media only screen and (max-width: 1024px) {
      width: 50%;
    }
    @media only screen and (max-width: 640px) {
      width: 100%;
      p {
        font-size: 1rem;
      }
    }
  }
`

const CircleAndText = ({ children, image }) => {
  return (
    <StyledCircleAndText className="full-width">
      <div className="candt_circle">
        <Img fluid={image.childImageSharp.fluid} />
      </div>
      {children}
    </StyledCircleAndText>
  )
}

export default CircleAndText

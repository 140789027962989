import React from "react"

const Line = ({ noPadding }) => {
  return (
    <div
      className="dividing_line"
      style={noPadding ? { padding: "0 0 0 0" } : {}}
    >
      <div className="dividing_line-inner" />
    </div>
  )
}

export default Line

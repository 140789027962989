import React from "react"
const dots = ({ amount }) => {
  let text = "On Track";
  if (amount === 0) {
    text = "Incomplete";
  } else if (amount === 3) {
    text = "Complete";
  }
  return (
    <div className={`${amount === 0 ? "dot-grey" : amount === 3 ? "dot-green" : "dot-blue"}`}>
      <div className="dots">
        <div className={`dot_single ${amount !== 0 ? "active" : ""}`} />
        <div className={`dot_single ${amount !== 0 ? "active" : ""}`} />
        <div className={`dot_single ${amount === 3 ? "active" : ""}`} />
      </div>
      <p className="dots_title">{ text }</p>
    </div>
  )
}

export default dots
